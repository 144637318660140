import React from 'react';
import { ChannelFragmentFragment } from 'teddly-sdk';

import { ProductList_products_edges_node } from 'teddly-sdk/lib/queries/gqlTypes/ProductList';
import { StoredPaymentSources_me_storedPaymentSources } from 'teddly-sdk/lib/queries/gqlTypes/StoredPaymentSources';

export type Product = ProductList_products_edges_node & {
  vendorLogo: string;
};

export interface CartProduct extends Product {
  quantity?: number;
  variant?: Product;
}

export type PaymentSource =
  StoredPaymentSources_me_storedPaymentSources['creditCardInfo'];

export type VendorDetails = {
  id: string;
  name: string;
  logo?: string;
  slug?: string;
  address?: string;
  channel?: ChannelFragmentFragment | {
    id: string
  };
};

export type Variant = {
  sku: string;
  attributes: {
    key: string;
    value: string;
  }[];
  vendorSlug?: string;
};

export type ImageSize = {
  list: string[];
  gallery: string[];
};

export type Category = {
  slug: string;
  name: string;
  background_image: string;
};

export type CartableProduct = {
  id: string; // variant id
  objectID: string; // variant id
  name: string;
  description: string;
  brand: string;
  image: string;
  images: ImageSize;
  slug: string;
  productId: string;
  vendor: VendorDetails;
  variant: Variant;
  collections: string[];
  categories: Category[];
  department: string;
  sku: string;
  total_sales: number;
  stock: boolean;
  variant_stock: boolean;
  is_sale: boolean;
  sale_price: number;
  price: number;
  price_gap: number;
  size: string;
  unit_size: number;
  by_weight: boolean;
  size_weight?: string;
  // extra
  customerNote: string;
  quantity: number;
  isMissing?: boolean;
};

export interface NewCartLine {
  quantity?: number;
  note?: string;
  is_missing?: boolean;
  price?: number;
  isInclude?: boolean;
  order?: { id: string; number?: string | null };
}
export type Collection = {
  name: string;
  slug: string;
  image?: string;
};
export type NewVariant = {
  orderLineId: string;
  id: string;
  product_id: string;
  vendor: VendorDetails;
  name: string;
  price: number;
  sale_price: number;
  is_fast_delivery: boolean;
  in_stock: boolean;
  unit_size: number;
  total_sales: number;
  thumbnail: string;
  images: string[];
  size_weight: string;
  line?: NewCartLine;
  by_weight: boolean;
  category?: CategoryItem;
  isInclude: boolean;
  isAvailableForPurchase?: boolean;
  product_promotion_images?: string[];
  attributes: {
    key: string;
    value: string;
  }[];
  promotion_images?: string[];
};
export type NewProduct = {
  objectID: string;
  id: string;
  brand: string;
  channel_id: string;
  name: string;
  description: string;
  collections: Collection[];
  supervisions: Collection[];
  categories: Category[];
  sku: string;
  total_sales: number;
  in_stock: boolean;
  promotion_images: string[];
  variants: NewVariant[];
  lastOrdered?: string;
  ordersQuantity?: number;
};

export type ProductUserData = {
  productId: string;
  lastOrdered: string;
  ordersQuantity: number;
};

export interface LastUpdated {
  seconds: number;
  nanoseconds: number;
}

export interface CategoryItem {
  name?: string;
  id?: string;
  imgSrc?: string;
  slug?: string;
  vendors_count?: number;
  parent?: CategoryItem;
  channel?: ChannelFragmentFragment;
  categories?: CategoryItem[];
}

export interface ShippingAddress {
  id: string;
  street: string;
  aptSuite: string;
  city: string;
  state: string;
  zipCode: string;
  isPrimary: boolean;
}

export interface PaymentDetails {
  id: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

export type NewShippingAddress = Omit<ShippingAddress, 'id'>;
export type NewPaymentDetails = Omit<PaymentDetails, 'id'>;

export type Promotion = {
  title: string;
  content: string;
  image: string;
};

export type SearchBarProps = {
  renderSuggestions?: () => React.ReactElement;
  showSuggestions?: boolean;
  errorState?: boolean;
  value: string;
  onChange: (val: string) => void;
  onEnter: (value) => void;
};

export enum PopupSize {
  'SMALL' = 'SMALL',
  'MEDIUM' = 'MEDIUM',
  'LARGE' = 'LARGE',
}

export type ActivePopup = {
  title: string;
  id: string;
  body: string;
  page: string;
  boxSize: PopupSize;
  endDate?: string | null;
  startDate?: string | null;
  channel?: ChannelFragmentFragment;
};
export type Banner = {
  source: string;
  link: string;
  title: string;
  buttonTitle: string;
  index: number;
};
