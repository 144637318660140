import { useEffect, useState } from 'react';

import useLocalStorageState from 'use-local-storage-state';

import { ActivePopup } from '@interfaces';

import { getActivePopups } from './api';
import { useChannelContext } from '@context/channel/ChannelContext';

export function useActivePopups(page?: string) {
  const {selectedChannel} = useChannelContext()
  const [popup, setPopup] = useState<ActivePopup>();
  const [popupsShown, setShownPopups] = useLocalStorageState<{
    [key: string]: boolean;
  }>(`popup_shown`, {});

  useEffect(() => {
    if (page) {
      (async () => {
        const allPopups = await getActivePopups();
        const activePopups = allPopups.filter(
          (p) => !popupsShown[p.id] && p.page === page && p?.channel?.id === selectedChannel?.id
        );

        if (activePopups.length > 0) {
          setPopup(activePopups[0]);
        }
      })();
    }
  }, [page, popupsShown]);

  const closePopup = () => {
    if (popup) {
      setPopup(undefined);
      // setShownPopups({
      //   ...popupsShown,
      //   [popup.id]: true,
      // });
    }
  };

  return [popup, closePopup] as [typeof popup, typeof closePopup];
}
